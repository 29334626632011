.HomeButton {
    display: inline-block; /* makes the container shrink to fit */
    font-size: 1em;
}

.HomeButton h1 {
    cursor: pointer;
    letter-spacing: 2px;
}

@media only screen and (min-width: 768px) {
    .HomeButton {
        font-size: 2.2em;
    }

    .HomeButton h1 {
        letter-spacing: 3px;
    }
}