input[type=text] {
    background-color: #fafafa;
    width: 90%;
    display: block;
    border: 0;
    outline: 0;
    border-bottom: 2px solid #777;
    margin-bottom: 2em;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: inherit;
}


textarea {
    background-color: #fafafa;
    width: 90%;
    display: block;
    border: 0;
    outline: 0;
    border-bottom: 2px solid #777;
    margin-bottom: 2em;
    padding-top: 4px;
    padding-bottom: 4px;
    resize: none;
    font-family: inherit;
}

button[type=submit].BasicButton {
    background-color: transparent;
    border: 1px solid black;
    border-radius: 8px;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    font-family: inherit;
    font-size: 1.25rem;
    letter-spacing: 2px;
}

button[type=submit].BasicButton:active {
    background-color: black;
    color: white;
}



@media only screen and (min-width: 768px) {
    input[type=text] {
        width: 60%;
    }

    textarea {
        width: 60%;
    }
}
