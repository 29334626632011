li.NavBarItem {
    display: inline-block;
    text-align: center;
}

.NavBarItemContent {
    display: block;
    margin-right: 16px;
    text-decoration: none;
    cursor: pointer;

    position: relative;
}

a.NavBarLink {
    color: inherit;
    text-decoration: inherit;
}

a.NavBarLink:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out;
}

a.NavBarLink:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

@media only screen and (min-width: 768px) {
    li.NavBarItem {
        display: block;
        float: left;
        text-align: left;
    }
    
    .NavBarItemContent {
        margin-right: 32px;
    }
}