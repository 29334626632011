
button.ExternalLinkButton {
    width: 113px;
    height: 64px;
    border: 1px solid black;
    border-radius: 8px;
    background-color: transparent;
    font-family: inherit;
    font-size: 1.25rem;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
    margin-right: 2em;
}

button.ExternalLinkButton:active {
    background-color: black;
    color: white;
}