.MobileNavBarOpener {
    display: inline-block;
    text-align: right;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid #555555;
    border-radius: 4px;
}

@media only screen and (min-width: 768px) {
    .MobileNavBarOpener {
        display: none;
    }
}