.NavBar ul {
    display: none;
}

.NavBar.active ul{
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1.25em;
}

@media only screen and (min-width: 768px) {
    .NavBar ul {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-size: 2em;
    }

    .NavBar.active ul{
        font-size: 2em;
    }
}