.BlurbSlideshowHolder {
    margin-top: 3em;
}
.BlurbSlideshow {
    width: 75%;
    height: 250px;
    margin: auto;
    position: relative;
}

/* wtf css */
.BlurbSlideshowDots {
    margin-top: 2em;
    text-align: center;
}

.BlurbSlideshow-blurb {
    display: block;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.BlurbSlideshow-dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active-dot {
    background-color: #717171;
}

/* Fading animation */
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}
  
@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
  
@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}