.LinkButton {
    text-decoration: none;
    color: inherit;
    margin: 4px 16px;
    display: inline-block;
}

.LinkButton:active {
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 4px;

}

.LinkButtonContent {
    border: 1px solid black;
    padding: 4px 16px;
    border-radius: 4px;
    display: block;
    text-decoration: none;
    cursor: pointer;
}
