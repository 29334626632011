.App-Header {
    padding-left: 6vw;
    padding-top: 0.25vw;
    padding-right: 5vw;
    padding-bottom: 1em;
    background-color: lavender;
}

.App-Header-FirstRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.App-Content {
    height: 100%;
}

.Basic-Content {
    padding-top: 4vh;
    margin-left: 6vw;
    margin-right: 5vw;
}

@media only screen and (min-width: 768px) {
    .App-Header {
        padding-left: 30vw;
        padding-top: 1vw;
        padding-right: 10vw;
        padding-bottom: 2em;
    }

    .Basic-Content {
        padding-top: 8vh;
    
        margin-left: 30vw;
        margin-right: 10vw;
    }
}